import { IBriefTableParams } from '../modules/brief/brief.types'

import BrandStoreBanner from '../assets/icons/banner-types/brand-store.svg'
import CashbackOffersBanner from '../assets/icons/banner-types/cashback-offer.svg'
import FreeDeliveryBanner from '../assets/icons/banner-types/free-delivery.svg'
import HomePageBannersBanner from '../assets/icons/banner-types/home-page-banners.svg'
import PopupWindowBanner from '../assets/icons/banner-types/popup-window.svg'
import SurveyBanner from '../assets/icons/banner-types/survey.svg'
import PriceOffPromoBanner from '../assets/icons/banner-types/price-off-promotion.svg'
import PushNotificationsBanner from '../assets/icons/banner-types/push-notifications.svg'
import FeedBanner from '../assets/icons/banner-types/feed.svg'
import SkuUpliftingBanner from '../assets/icons/banner-types/sku-uplifting.svg'
import SocialMediaBanner from '../assets/icons/banner-types/social-media-ads.svg'
import CustomBanner from 'src/assets/icons/banner-types/custom-design.svg'

export const DEFAULT_BRIEF_TABLE_PARAMS: IBriefTableParams = {
  offset: 0,
  limit: 10,
  direction: 'desc',
  sortField: 'createdAt',
  search: '',
  status: [],
  paymentStatus: [],
}

export enum BRIEF_STEPS {
  TYPE = 'BRIEF_TYPE',
  PRODUCT = 'BRIEF_PRODUCT',
  TARGETING = 'BRIEF_TARGETING',
  SERVICES = 'BRIEF_SERVICES',
  MEDIA_BUY = 'BRIEF_MEDIA_BUY',
  PARTNER = 'BRIEF_PARTNER',
  UPLOAD = 'BRIEF_UPLOAD',
  PAYMENT = 'BRIEF_PAYMENT',
  SUMMARY = 'SUMMARY',
}

export enum TARGETING_TOGGLES {
  GENDER = 'targeting_gender',
  AGE_GROUP = 'targeting_age_group',
  LOCATION = 'targeting_location',
}

export const CREATE_BRIEF_STEPS: BRIEF_STEPS[] = [
  BRIEF_STEPS.TYPE,
  BRIEF_STEPS.PRODUCT,
  BRIEF_STEPS.TARGETING,
  BRIEF_STEPS.PARTNER,
  BRIEF_STEPS.UPLOAD,
  BRIEF_STEPS.SUMMARY,
  BRIEF_STEPS.PAYMENT
]

export const REJECTED_BRIEF_STEPS: BRIEF_STEPS[] = [BRIEF_STEPS.PARTNER, BRIEF_STEPS.UPLOAD, BRIEF_STEPS.SUMMARY]

export const BRIEF_STEPS_NAMES: { [key: string]: string } = {
  [BRIEF_STEPS.TYPE]: 'Brief Type',
  [BRIEF_STEPS.PRODUCT]: 'brief.edit.common.campaign_info',
  [BRIEF_STEPS.TARGETING]: 'brief.edit.common.targeting',
  [BRIEF_STEPS.PARTNER]: 'brief.edit.common.services',
  [BRIEF_STEPS.UPLOAD]: 'brief.edit.common.upload',
  [BRIEF_STEPS.SUMMARY]: 'brief.edit.common.summary',
  [BRIEF_STEPS.PAYMENT]: 'brief.edit.common.payment',
  // [BRIEF_STEPS.SUMMARY]: 'brief.edit.common.review_submit',
}

export enum BRIEF_STATUS_GROUP {
  BRIEF = 'BRIEF',
  PAYMENT = 'PAYMENT',
}

export enum BRIEF_STATUS {
  REJECTED = 'REJECTED',
  PENDING = 'PENDING',
  DRAFT = 'DRAFT',
  APPROVED = 'APPROVED',
  ACCEPTED = 'ACCEPTED',
  EXPIRED = 'EXPIRED',
  PARTIALLY_REJECTED = 'PARTIALLY_REJECTED',
  PARTIALLY_APPROVED = 'PARTIALLY_APPROVED',
  NO_RESPONSE = 'NO_RESPONSE',
  PENDING_APPROVAL = 'PENDING APPROVAL',
  PENDING_ACCOUNT_APPROVAL = 'PENDING_ACCOUNT_APPROVAL',
  SELECT_PARTNERS = 'SELECT_PARTNERS',
  PAID = 'PAID',
  MISSING_INFO = 'MISSING_INFO',
}

export const BRIEF_STATUS_NAMES: { [key: string]: string } = {
  [BRIEF_STATUS.DRAFT]: 'brief.brief-statuses-array.DRAFT',
  [BRIEF_STATUS.PENDING]: 'brief.brief-statuses-array.PENDING',
  [BRIEF_STATUS.PENDING_ACCOUNT_APPROVAL]: 'brief.brief-statuses-array.PENDING_ACCOUNT_APPROVAL',
  [BRIEF_STATUS.SELECT_PARTNERS]: 'brief.brief-statuses-array.SELECT_PARTNERS',
  [BRIEF_STATUS.PENDING_APPROVAL]: 'brief.brief-statuses-array.PENDING_APPROVAL',
  [BRIEF_STATUS.PARTIALLY_REJECTED]: 'brief.brief-statuses-array.PARTIALLY_REJECTED',
  [BRIEF_STATUS.PARTIALLY_APPROVED]: 'brief.brief-statuses-array.PARTIALLY_APPROVED',
  [BRIEF_STATUS.APPROVED]: 'brief.brief-statuses-array.APPROVED',
  [BRIEF_STATUS.REJECTED]: 'brief.brief-statuses-array.REJECTED',
  [BRIEF_STATUS.EXPIRED]: 'brief.brief-statuses-array.EXPIRED',
  [BRIEF_STATUS.NO_RESPONSE]: 'brief.brief-statuses-array.NO_RESPONSE',
  [BRIEF_STATUS.MISSING_INFO]: 'brief.brief-statuses-array.MISSING_INFO',
}

export const BRIEF_STATUS_LIST = [
  BRIEF_STATUS.DRAFT,
  BRIEF_STATUS.PENDING,
  // BRIEF_STATUS.PENDING_ACCOUNT_APPROVAL,
  // BRIEF_STATUS.SELECT_PARTNERS,
  // BRIEF_STATUS.PENDING_APPROVAL,
  // BRIEF_STATUS.PARTIALLY_REJECTED,
  // BRIEF_STATUS.PARTIALLY_APPROVED,
  BRIEF_STATUS.APPROVED,
  BRIEF_STATUS.REJECTED,
  // BRIEF_STATUS.EXPIRED,
  // BRIEF_STATUS.NO_RESPONSE,
  BRIEF_STATUS.MISSING_INFO,
]

export const briefStatuses = {
  [BRIEF_STATUS.PENDING]: {
    classStyle: 'pending',
    label: 'brief.brief-statuses-array.PENDING',
  },
  [BRIEF_STATUS.PENDING_ACCOUNT_APPROVAL]: {
    classStyle: 'partially running account_approval',
    label: 'brief.brief-statuses-array.PENDING_ACCOUNT_APPROVAL',
  },
  [BRIEF_STATUS.SELECT_PARTNERS]: {
    classStyle: 'partially running',
    label: 'brief.brief-statuses-array.SELECT_PARTNERS',
  },
  [BRIEF_STATUS.DRAFT]: {
    classStyle: 'running',
    label: 'brief.brief-statuses-array.DRAFT',
  },
  [BRIEF_STATUS.APPROVED]: {
    classStyle: 'approved',
    label: 'brief.brief-statuses-array.APPROVED',
  },
  [BRIEF_STATUS.ACCEPTED]: {
    classStyle: 'approved',
    label: 'brief.brief-statuses-array.APPROVED',
  },
  [BRIEF_STATUS.PARTIALLY_APPROVED]: {
    classStyle: 'partially approved',
    label: 'brief.brief-statuses-array.PARTIALLY_APPROVED',
  },
  [BRIEF_STATUS.REJECTED]: {
    classStyle: 'rejected',
    label: 'brief.brief-statuses-array.REJECTED',
  },
  [BRIEF_STATUS.PENDING_APPROVAL]: {
    classStyle: 'partially rejected',
    label: 'brief.brief-statuses-array.PENDING_APPROVAL',
  },
  [BRIEF_STATUS.PARTIALLY_REJECTED]: {
    classStyle: 'partially rejected',
    label: 'brief.brief-statuses-array.PARTIALLY_REJECTED',
  },
  [BRIEF_STATUS.NO_RESPONSE]: {
    classStyle: 'rejected',
    label: 'brief.brief-statuses-array.NO_RESPONSE',
  },
  [BRIEF_STATUS.EXPIRED]: {
    classStyle: 'completed',
    label: 'brief.brief-statuses-array.EXPIRED',
  },
  [BRIEF_STATUS.PAID]: {
    classStyle: 'completed',
    label: 'paid',
  },
  [BRIEF_STATUS.MISSING_INFO]: {
    classStyle: 'pending',
    label: 'brief.brief-statuses-array.MISSING_INFO',
  },
}

export enum BRIEF_PAYMENT_UPLOAD_TYPES {
  PO = 'PO',
  BANK_TRANSFER = 'BANK_TRANSFER',
  ONLINE = 'ONLINE',
}

export enum BRIEF_PAYMENT_STATUS {
  PENDING = 'PENDING',
  PROCESSING = 'PROCESSING',
  PARTIAL_REFUND = 'PARTIAL_REFUND',
  REFUND = 'REFUND',
  PAID = 'PAID',
  DECLINED = 'DECLINED',
  REJECTED = 'REJECTED',
  PO_ISSUED = 'PO_ISSUED',
  PAYOUT_COMPLETE = 'PAYOUT_COMPLETE',
}

export const BRIEF_PAYMENT_STATUS_NAMES: { [key in BRIEF_PAYMENT_STATUS]: string } = {
  [BRIEF_PAYMENT_STATUS.PENDING]: 'brief.payment-statuses-array.PENDING',
  [BRIEF_PAYMENT_STATUS.PROCESSING]: 'brief.payment-statuses-array.PROCESSING',
  [BRIEF_PAYMENT_STATUS.PARTIAL_REFUND]: 'brief.payment-statuses-array.PARTIAL_REFUND',
  [BRIEF_PAYMENT_STATUS.REFUND]: 'brief.payment-statuses-array.REFUND',
  [BRIEF_PAYMENT_STATUS.PAID]: 'brief.payment-statuses-array.PAID',
  [BRIEF_PAYMENT_STATUS.DECLINED]: 'brief.payment-statuses-array.DECLINED',
  [BRIEF_PAYMENT_STATUS.REJECTED]: 'brief.payment-statuses-array.REJECTED',
  [BRIEF_PAYMENT_STATUS.PO_ISSUED]: 'brief.payment-statuses-array.PO_ISSUED',
  [BRIEF_PAYMENT_STATUS.PAYOUT_COMPLETE]: 'brief.payment-statuses-array.PAYOUT_COMPLETE',
}

export const BRIEF_PAYMENT_STATUS_LIST = [
  BRIEF_PAYMENT_STATUS.PENDING,
  // BRIEF_PAYMENT_STATUS.PROCESSING,
  BRIEF_PAYMENT_STATUS.PARTIAL_REFUND,
  BRIEF_PAYMENT_STATUS.REFUND,
  BRIEF_PAYMENT_STATUS.PAID,
  // BRIEF_PAYMENT_STATUS.DECLINED,
  BRIEF_PAYMENT_STATUS.REJECTED,
  BRIEF_PAYMENT_STATUS.PO_ISSUED,
  BRIEF_PAYMENT_STATUS.PAYOUT_COMPLETE,
]

export const briefPaymentStatuses = {
  [BRIEF_PAYMENT_STATUS.PENDING]: {
    classStyle: 'pending',
    label: 'brief.payment-statuses-array.PENDING',
  },
  [BRIEF_PAYMENT_STATUS.PROCESSING]: {
    classStyle: 'pending',
    label: 'brief.payment-statuses-array.PROCESSING',
  },
  [BRIEF_PAYMENT_STATUS.PARTIAL_REFUND]: {
    classStyle: 'running',
    label: 'brief.payment-statuses-array.PARTIAL_REFUND',
  },
  [BRIEF_PAYMENT_STATUS.REFUND]: {
    classStyle: 'rejected',
    label: 'brief.payment-statuses-array.REFUND',
  },
  [BRIEF_PAYMENT_STATUS.PAID]: {
    classStyle: 'approved',
    label: 'brief.payment-statuses-array.PAID',
  },
  [BRIEF_PAYMENT_STATUS.DECLINED]: {
    classStyle: 'rejected',
    label: 'brief.payment-statuses-array.DECLINED',
  },
  [BRIEF_PAYMENT_STATUS.REJECTED]: {
    classStyle: 'rejected',
    label: 'brief.payment-statuses-array.REJECTED',
  },
  [BRIEF_PAYMENT_STATUS.PO_ISSUED]: {
    classStyle: 'approved',
    label: 'brief.payment-statuses-array.PO_ISSUED',
  },
  [BRIEF_PAYMENT_STATUS.PAYOUT_COMPLETE]: {
    classStyle: 'approved',
    label: 'brief.payment-statuses-array.PAYOUT_COMPLETE',
  },
}

export enum BRIEF_STEP_STATUS {
  CURRENT = 'current',
  PASSED = 'passed',
  DISABLED = 'disabled',
  DEFAULT = '',
}

export enum BRIEF_TYPES {
  PRODUCT_SAMPLE = 'PRODUCT_SAMPLE',
  MEDIA_BUYING = 'MEDIA_BUYING',
  CONSUMER_RESEARCH = 'CONSUMER_RESEARCH',
  CUSTOMIZED_TESTAHEL_BOX = 'CUSTOMIZED_TESTAHEL_BOX',
}

export enum GENDER {
  MALE = 'Male',
  FEMALE = 'Female',
  BOTH = 'Both',
}

export enum PROMOTION_TYPE {
  STORE = 'Store',
  BRAND = 'Brand',
  RESTAURANT = 'Restaurant'
}

export enum PROMOTION_TYPE_LABEL {
  STORE = 'brief.edit.step2.store',
  BRAND = 'brief.edit.step2.brand',
  RESTAURANT = 'brief.edit.step2.restaurant',
}

export enum MARKETING_OBJECTIVES {
  BRAND_AWARENESS = 'Brand Awareness',
  REACH = 'Reach',
  LEAD_GENERATION = 'Lead Generation',
  VIDEO_VIEW = 'Video View',
  USER_ENGAGEMENT = 'User Engagement',
  BRAND_RECOGNITION = 'Brand Recognition',
  DRIVE_SALES = 'Drive Sales',
}

export enum MARKETING_OBJECTIVES_LABELS {
  BRAND_AWARENESS = 'brief.media-buy.marketing-objective.brand_awareness',
  REACH = 'brief.media-buy.marketing-objective.reach',
  LEAD_GENERATION = 'brief.media-buy.marketing-objective.lead_generation',
  VIDEO_VIEW = 'brief.media-buy.marketing-objective.video_view',
  USER_ENGAGEMENT = 'brief.media-buy.marketing-objective.user_engagement',
  BRAND_RECOGNITION = 'brief.media-buy.marketing-objective.brand_recognition',
  DRIVE_SALES = 'brief.media-buy.marketing-objective.drive_sales',
}

export enum MEDIA_TYPES {
  HOME_BANNERS = 'Banner',
  PRICE_OFF_PROMO = 'Price Off Promotion',
  POPUP_WINDOW = 'Popup Window',
  BRAND_STORE = 'Brand Store (Brand Bundle)',
  PUSH_NOTIFICATIONS = 'Push Notifications',
  FEED = 'Feed',
  VIDEO = 'Video',
  SURVEY = 'Survey',
  CASHBACK = 'Cashback Offers',
  SOCIAL_MEDIA = 'Paid SM campaign',
  SKU = 'SKU Uplifting',
  FREE_DELIVERY = 'Free Delivery',
  DISTRIBUTION = 'Distribution',
  CUSTOM_MEDIA_SERVICE = 'Custom Media Service'
}

export const BANNERS_BY_MEDIA_TYPE: { [key: string]: { image: any; description: string } } = {
  [MEDIA_TYPES.BRAND_STORE]: {
    image: BrandStoreBanner,
    description: '',
  },
  [MEDIA_TYPES.CASHBACK]: {
    image: CashbackOffersBanner,
    description: 'Offer cash back incentives to buyers of your product.',
  },
  [MEDIA_TYPES.FREE_DELIVERY]: {
    image: FreeDeliveryBanner,
    description: '',
  },
  [MEDIA_TYPES.HOME_BANNERS]: {
    image: HomePageBannersBanner,
    description: 'The largest and most prominent piece of real estate on a website.',
  },
  [MEDIA_TYPES.POPUP_WINDOW]: {
    image: PopupWindowBanner,
    description: 'An ad that appears either in a modal or popup window after visiting a page.',
  },
  [MEDIA_TYPES.SURVEY]: {
    image: SurveyBanner,
    description: 'Conduct research by allowing visitors to complete a brief survey.',
  },
  [MEDIA_TYPES.PRICE_OFF_PROMO]: {
    image: PriceOffPromoBanner,
    description: 'An ad that appears either in a modal or popup window after visiting a page.',
  },
  [MEDIA_TYPES.PUSH_NOTIFICATIONS]: {
    image: PushNotificationsBanner,
    description: 'Trigger a push notification with an ad either in an app or via SMS.',
  },
  [MEDIA_TYPES.FEED]: {
    image: FeedBanner,
    description: 'Advertise with video on a partner’s platform feed.',
  },
  [MEDIA_TYPES.VIDEO]: {
    image: FeedBanner,
    description: 'Advertise with video on a partner’s platform.',
  },
  [MEDIA_TYPES.SKU]: {
    image: SkuUpliftingBanner,
    description: 'Promote an individual product listing prominently on a website.',
  },
  [MEDIA_TYPES.SOCIAL_MEDIA]: {
    image: SocialMediaBanner,
    description: 'Advertise on a partner’s social media feed or channel.',
  },
  [MEDIA_TYPES.CUSTOM_MEDIA_SERVICE]: {
    image: CustomBanner,
    description: 'A customized media service.',
  },
}

export enum ADDITIONAL_STEPS {
  MEDIA_BUY_DIALOG = 'MEDIA_BUY_DIALOG',
  PRODUCT_SAMPLE_DIALOG = 'PRODUCT_SAMPLE_DIALOG',
  SUMMARY = 'SUMMARY',
  FINAL_SUMMARY = 'FINAL_SUMMARY',
  CONGRATS = 'CONGRATS',
  SAVED = 'SAVED'
}

export enum BRIEF_UPLOAD_TYPES {
  BRIEFS_PRODUCT_IMAGES = 'briefs_product_images',
  BRIEFS_SERVICE_IMAGES = 'briefs_service_images',
  BRIEFS_UPLOAD_IMAGES = 'briefs_upload_materials_images',
  BRIEFS_QUOTATION_FILES = 'briefs_quotation_files',
}

export enum BRIEF_SORT_TYPE {
  BEST_MATCH = 'Best Match',
  PRICE = 'Price',
  AUDIENCE = 'Audience/Customer Size',
}

export enum MEDIA_TYPES_DURATION {
  PER_SAMPLE = 'Per Sample',
  PER_INSERT = 'Per Insert',
  PER_DAY = 'Per Day',
  PER_WEEK = 'Per Week',
  PER_MONTH = 'Per Month',
  PER_PUSH = 'Per Push',
  PER_ACTIVITY = 'Per Activity'
}

export enum LOGISTIC_RATES {
  SMALLEST = 2500,
  BIGGEST = Number.MAX_SAFE_INTEGER,
}

export const MANAGEMENT_FEE_PERCENT = 7
export const TAXES_PERCENT = 15
export const DECIMAL_DIGIT = 4
export const PRODUCT_NAME_MIN = 3
export const PRODUCT_NAME_MAX = 50
export const CAMPAIGN_NAME_MIN = 3
export const CAMPAIGN_NAME_MAX = 50
export const PRODUCT_DESCRIPTION_MIN = 3
export const PRODUCT_DESCRIPTION_MAX = 500

export enum DISTRIBUTION_TYPE {
  PRODUCT_SAMPLE = 'Product Sample',
  PRINTED_INSERT = 'Printed Insert',
}

export enum MEDIA_GROUP_TYPE {
  MEDIA_BUY = 'Media Buy',
  PRODUCT_SAMPLE = 'Product Sample',
  PRINTED_INSERT = 'Printed Insert',
}

type TSERVICE_NAMES_FOR_BRIEF_TABLE = {
  [key: string]: string
}

export const SERVICE_NAMES_FOR_BRIEF_TABLE: TSERVICE_NAMES_FOR_BRIEF_TABLE = {
  MEDIA: 'Media Buying',
  MEDIA_BUYING: 'Media Buying',
  PRODUCT: 'Product Sample Distribution',
  INSERT: 'Printed Insert Distribution',
  EVENT: 'Event Sample Distribution',
  CONSUMER_RESEARCH: 'Consumer Research',
  FOCUS_GROUP: 'Market Research', //for older briefs before focus group was changed to market research
  CUSTOMIZED_TESTAHEL_BOX: 'Customized Box',
}

export enum SERVICE_NAMES_FOR_TABLE {
  MEDIA = 'Media Buying',
  MEDIA_BUYING = 'Media Buying',
  PRODUCT = 'Product Sample Distribution',
  INSERT = 'Printed Insert Distribution',
  EVENT = 'Event Sample Distribution',
  CONSUMER_RESEARCH = 'Consumer Research',
  FOCUS_GROUP = 'Market Research', //for older briefs before focus group was changed to market research
  CUSTOMIZED_TESTAHEL_BOX = 'Customized Box',
}

export enum DIALOG_MODALS {
  HAS_REJECTED_SERVICES = 'HAS_REJECTED_SERVICES',
  SAMPLE_ALLOCATED_MISMATCH = 'SAMPLE_ALLOCATED_MISMATCH',
  BUDGET_ALLOCATED_MISMATCH = 'BUDGET_ALLOCATED_MISMATCH',
  SAMPLE_BUDGET_ALLOCATED_MISMATCH = 'SAMPLE_BUDGET_ALLOCATED_MISMATCH',
  BLOCK_NEXT = 'BLOCK_NEXT',
  CANCEL = 'CANCEL',
  MEDIA_BUY = 'MEDIA_BUY',
  DISTRIBUTION = 'DISTRIBUTION',
}

export const MIN_LOGISTIC_PRICE_TEXT = 'Minimal Logistic Price'

export const KEEP_MODAL = 'KEEP_MODAL'

export enum PARTNER_STEP_BUTTONS {
  ADD_TO_CART = 'addToCart',
  NEXT = 'next',
  BACK = 'back',
}
export enum BRIEF_FORM_HEADER_TITLE {
  EDIT = 'brief.header-title.EDIT',
  CREATE = 'brief.header-title.CREATE',
  REJECTION = 'brief.header-title.REJECTION',
}

export const BRIEF_PRINT_ID = 'brief_print_area'
export const MAIN_PRINT_ID = 'brief_main'

export const BRIEF_ADVERTISEMENT_LANGUAGES = {
  english: 'English',
  arabic: 'Arabic',
}

export enum BRIEF_PAYMENT_SELECTED_TYPES {
  PO = 'PO',
  BANK_TRANSFER = 'BANK_TRANSFER',
  ONLINE = 'ONLINE',
  WALLET = 'WALLET',
}

export const BRIEF_PAYMENT_SELECTED_OPTIONS: BRIEF_PAYMENT_SELECTED_TYPES[] = [
  BRIEF_PAYMENT_SELECTED_TYPES.PO,
  BRIEF_PAYMENT_SELECTED_TYPES.BANK_TRANSFER,
  BRIEF_PAYMENT_SELECTED_TYPES.ONLINE,
]
