import { Col, Divider, Row, Tag, Button, Upload, Tooltip } from "antd";
import { AgeGroupCard } from "src/modules/brief/containers/components/Brief.AgeGroupCard";
import lodash, { divide } from "lodash";
import React, { useEffect, useState } from "react";
import SpinnerCenter from "src/components/Spinner/spinner";
import { IconsCommon, Modal, QButton, QInput } from "quantum_components";
import { renderRoundedNumber } from "src/helpers/currency";
import { CURRENCY } from "src/constants/currencies";
import { Radio } from "quantum_components";
import {
  downloadProtectedMedia,
  removeBriefPaymentFile,
  uploadBriefPaymentFile,
  validateClientRequiredFieldsAsync,
} from "../briefv3.actions";
import { getBriefQuotationPdf } from "src/utils/getScreenPage";
import V3UploadComponent from "./V3Upload.component";
import { getFileNameFromUrl } from "src/utils";
import { isUploadFile } from "src/type-guards";
import {
  DeleteOutlined,
  EyeOutlined,
  InfoCircleOutlined,
} from "@ant-design/icons";
import { useSelector } from "react-redux";
import { getCurrentUser } from "src/modules/core/core.selectors";
import BriefPaymentModal from "src/modules/brief/containers/components/Brief.PaymentModal";
import { useHistory } from "react-router-dom";
import { FORMATS, PATHS } from "src/constants";
import V3OnlinePaymentModal from "./V3PaymentModal.component";
import { TAX_TREATMENT } from "src/constants/clientProfile";
import moment from "moment";
import { useTranslation } from "react-i18next";

const V3PaymentMethods = ({ briefResponse, parentRefresh, isAdmin }: any) => {
  const { t } = useTranslation(["translationV2", "translation"]);
  const history = useHistory();
  const [paymentModalOpen, setPaymentModalOpen] = useState(false);
  const [clientRequiredFieldsOpen, setClientRequiredFieldsOpen] =
    useState<boolean>(false);
  const currentUser = useSelector(getCurrentUser);
  const [isDisabled, setIsDisabled] = useState(true);
  const [isUploading, setIsUploading] = useState(false);
  const [showBankingDetails, setShowBankingDetails] = useState(false);

  useEffect(() => {
    if (!briefResponse) return;
    setIsDisabled(shouldDisableUpload());
  }, [briefResponse]);

  const shouldDisableUpload = () => {
    if (
      ["PROCESSING", "PAID", "PO_ISSUED", "PARTIAL_REFUND", "REFUND"].includes(lodash.get(briefResponse, 'rawResponse.payment.status')) ||
      (Array.isArray(
        lodash.get(briefResponse, `rawResponse.payment.bankTransfer`)
      ) &&
        lodash.get(briefResponse, `rawResponse.payment.bankTransfer`).length >
        0) ||
      (Array.isArray(lodash.get(briefResponse, `rawResponse.payment.po`)) &&
        lodash.get(briefResponse, `rawResponse.payment.po`).length > 0)
    ) {
      return true;
    }

    return false;
  };

  const handleOnChange = async (type: string, data: any) => {
    // console.log(type,data?.file?.originFileObj)
    // return false;
    setIsUploading(true);
    await uploadBriefPaymentFile(
      [data?.file?.originFileObj],
      type,
      briefResponse?.rawResponse
    );
    await parentRefresh();
    setIsUploading(false);
    return false;
  };

  const handleOnRemove = async (type: string, url: any) => {
    setIsUploading(true);
    await removeBriefPaymentFile(url, type, briefResponse?.rawResponse);
    await parentRefresh();
    setIsUploading(false);
    return false;
  };

  const renderModeration = (paymentType: string) => {
    return (
      <div className="mt-10" key={paymentType}>
        {lodash.get(briefResponse, `rawResponse.payment.${paymentType}`) &&
          lodash.get(briefResponse, `rawResponse.payment.${paymentType}`, [])
            .length > 0 &&
          briefResponse?.rawResponse?.payment?.status === "REJECTED" && (
            <div className="rejection-reason">
              {briefResponse?.rawResponse?.payment?.reason}
            </div>
          )}
      </div>
    );
  };

  const previewProtectedFile = async (fileUrl: string) => {
    const response: any = await downloadProtectedMedia(fileUrl);
    const url = URL.createObjectURL(response.data);
    window.open(url, "_blank");
    URL.revokeObjectURL(url);
    return;
  };

  const customItemRenderer = (type: string, files: any[]) => {
    return (
      <>
        {files.map((url: any, index: any) => {
          return (
            <div
              className="flex-row mt-15"
              key={index}
              style={{ background: "#f6f6f6", borderRadius: 5, padding: 5 }}
            >
              <div>
                <a onClick={() => previewProtectedFile(url)}>
                  <EyeOutlined /> {getFileNameFromUrl(url)}
                </a>{" "}
              </div>
              {["PENDING", "REJECTED"].includes(
                briefResponse?.rawResponse?.payment?.status
              ) && (
                  <div>
                    {<DeleteOutlined onClick={() => handleOnRemove(type, url)} />}
                  </div>
                )}
            </div>
          );
        })}
      </>
    );
  };

  const renderBankTransfer = () => {
    return (
      <div>
        <div className="flex-row">
          <div className="text-bold">
            {t("Bank Transfer")}&nbsp;
            <a onClick={() => setShowBankingDetails(true)}>
              (<InfoCircleOutlined /> {t("Bank Details")})
            </a>
          </div>
        </div>
        <div className="mt-10">
          <Upload
            onChange={(e) => handleOnChange("bankTransfer", e)}
            onRemove={(e) => handleOnRemove("bankTransfer", e)}
            multiple={false}
            customRequest={() => false}
            showUploadList={false}
            listType={"text"}
            maxCount={1}
          // disabled={true}
          >
            <QButton
              size="small"
              type="primary"
              htmlType="button"
              className="qu-button-outline xs-btn"
              disabled={isDisabled}
            >
              {t("Upload")}
            </QButton>
          </Upload>
        </div>
        {customItemRenderer(
          "bankTransfer",
          briefResponse?.rawResponse?.payment?.bankTransfer ?? []
        )}
        {renderModeration("bankTransfer")}
      </div>
    );
  };

  const renderPO = () => {
    return (
      <div>
        <div className="flex-row">
          <div className="text-bold">
            {t("Purchase Order")}&nbsp;
            <a onClick={() => setShowBankingDetails(true)}>
              (<InfoCircleOutlined /> {t("Bank Details")})
            </a>
          </div>
        </div>
        <div className="mt-10">
          <Upload
            onChange={(e) => handleOnChange("po", e)}
            onRemove={(e) => handleOnRemove("po", e)}
            multiple={false}
            customRequest={() => null}
            showUploadList={false}
            listType={"text"}
            maxCount={1}
          // disabled={true}
          >
            <QButton
              size="small"
              type="primary"
              htmlType="button"
              className="qu-button-outline xs-btn"
              disabled={isDisabled}
            >
              {t("Upload")}
            </QButton>
          </Upload>
        </div>
        {customItemRenderer(
          "po",
          briefResponse?.rawResponse?.payment?.po ?? []
        )}
        {renderModeration("po")}
      </div>
    );
  };

  const renderOnlinePayment = () => {
    const onlinePayment = lodash.get(
      briefResponse,
      "rawResponse.payment.onlinePayment"
    );
    const currentPaymentStatus = onlinePayment?.status;
    const transactionId = onlinePayment?.transactionId;
    const declineReason = currentPaymentStatus === 'DECLINED' && onlinePayment?.declineReason;
    const refunds = onlinePayment?.refunds ?? [];
    const invoice = lodash.get(briefResponse, 'rawResponse.payment.invoice') ?? [];

    const getCurrentPaymentClass = () => {
      if (currentPaymentStatus === "PAID") return "approved";
      if (currentPaymentStatus === "PROCESSING") return "pending";
      if (currentPaymentStatus === "DECLINED") return "rejected";
    };

    return (
      <>
        <div>
          <div className="text-bold">{t("Online Payment")}&nbsp;
            <a onClick={() => null}>
              <Tooltip data-html="true" title={t("15% VAT is charged for all online payment transactions")}>
                <span
                  style={{
                    fontWeight: "bold",
                    fontSize: "12px",
                    cursor: "pointer",
                  }}
                >
                  (<InfoCircleOutlined />)
                </span>
              </Tooltip>

            </a></div>
        </div>
        {!isAdmin && (
          <div className="mt-10">
            <QButton
              size="small"
              type="primary"
              htmlType="button"
              className="qu-button-outline xs-btn"
              disabled={isDisabled}
              onClick={() => setPaymentModalOpen(true)}
            >
              {t("Pay Now")}
            </QButton>
          </div>
        )}
        {onlinePayment && (
          <div className="mt-15" style={{ background: "#f6f6f6", borderRadius: 5, padding: 5 }}>
            {t("Transaction #")} {transactionId}
          </div>
        )}
        {onlinePayment && declineReason && (
          <div className={`mt-10 status-label ${getCurrentPaymentClass()}`}>
            {declineReason}
          </div>
        )}
        {onlinePayment && refunds.map(((refund, index) => {
          return <div key={index} className="mt-10" style={{ background: "#f6f6f6", borderRadius: 5, padding: 5 }}>
            {t("A refund of")} <span className="text-bold">{refund.currency} {Number(refund.amount / 100).toFixed(2)}</span> {t("was made on")} <span className="text-bold">{moment(refund.createdAt).format(FORMATS.DATE_LL_FORMAT)}</span> {t("with")} <span className="text-bold">{t("Payment #")} {refund.paymentId}</span>
          </div>
        }))}
        {invoice && invoice.length > 0 && <div>
          <a
            onClick={() => previewProtectedFile(invoice[0])}
            target="_blank"
            className="ant-btn qu-button full-width justify-center qu-button-soft mt-15 ant-btn-primary"
          >
            {t("View Invoice")}
          </a>
        </div>}
        <div className="font-small text-italic text-grey mt-10">
          {t("Powered by Quantum")}
        </div>
      </>
    );
  };

  return (
    <>
      {isUploading && <SpinnerCenter></SpinnerCenter>}

      {!currentUser?.paymentEnabled && (
        <div>{t("Payment is diabled for this account")}</div>
      )}

      {currentUser?.paymentEnabled &&
        currentUser?.bankTransfer &&
        renderBankTransfer()}
      {currentUser?.paymentEnabled && currentUser?.bankTransfer && (
        <Divider></Divider>
      )}

      {currentUser?.paymentEnabled && currentUser?.purchaseOrder && renderPO()}
      {/* {currentUser?.paymentEnabled && currentUser?.purchaseOrder && (
        <Divider></Divider>
      )}

      {currentUser?.paymentEnabled &&
        currentUser?.onlinePayment &&
        renderOnlinePayment()} */}

      {showBankingDetails && (
        <Modal
          wrapClassName="qu-modal"
          title={<h3>{t("Bank Details")}</h3>}
          closeIcon={<IconsCommon.IconClose />}
          visible={showBankingDetails}
          onCancel={() => setShowBankingDetails(false)}
          footer={false}
        >
          <div className="SSUnit">
            <h4 className="SSUnit__title mb-10">
              {currentUser?.partnerData?.name ?? "-"}
            </h4>

            <div className="SSUnit__content">
              <ul className="BSUnit__list">
                <li className="BSUnit__list-item">
                  {currentUser?.partnerData?.bankDetails?.address ?? "-"}
                </li>
                <br />
                {
                  <>
                    <li className="BSUnit__list-item">
                      <span className="BSUnit__content-title">{t("VAT")}:</span>{" "}
                      {currentUser?.partnerData?.taxTreatment ==
                        TAX_TREATMENT.NON_VAT_REGISTERED
                        ? "-"
                        : currentUser?.partnerData?.vat}
                    </li>
                    <li className="BSUnit__list-item">
                      <span className="BSUnit__content-title">
                        {t("Bank Name")}:
                      </span>{" "}
                      {currentUser?.partnerData?.bankDetails?.bank_name ?? "-"}
                    </li>
                    <li className="BSUnit__list-item">
                      <span className="BSUnit__content-title">
                        {t("Address")}:
                      </span>{" "}
                      {currentUser?.partnerData?.bankDetails?.bank_address ??
                        "-"}
                    </li>
                    <li className="BSUnit__list-item">
                      <span className="BSUnit__content-title">
                        {t("Account No")}:
                      </span>{" "}
                      {currentUser?.partnerData?.bankDetails?.account_number ??
                        "-"}
                    </li>
                    <li className="BSUnit__list-item">
                      <span className="BSUnit__content-title">
                        {t("IBAN No")}:
                      </span>{" "}
                      {currentUser?.partnerData?.bankDetails?.iban_number ??
                        "-"}{" "}
                      {/* 00369710{' '} */}
                    </li>
                    <li className="BSUnit__list-item">
                      <span className="BSUnit__content-title">
                        {t("Swift Code")}:
                      </span>{" "}
                      {currentUser?.partnerData?.bankDetails?.swift_code ?? "-"}
                    </li>
                    <li className="BSUnit__list-item">
                      <span className="BSUnit__content-title">
                        {t("Currency")}:
                      </span>{" "}
                      {currentUser?.partnerData?.bankDetails
                        ?.account_currency ?? "-"}
                    </li>
                  </>
                }
              </ul>
            </div>
          </div>
        </Modal>
      )}

      {paymentModalOpen && (
        <V3OnlinePaymentModal
          briefResponse={briefResponse}
          // briefId={Number(briefResponse?.rawResponse?.payment?.briefId)}
          onCancel={() => setPaymentModalOpen(false)}
          onPaySuccess={async () => {
            await parentRefresh();
            const res: any = await validateClientRequiredFieldsAsync(
              Number(briefResponse?.rawResponse?.payment?.briefId)
            );
            if (res) {
              if (res.missingInfo) {
                setPaymentModalOpen(false);
                setClientRequiredFieldsOpen(true);
              } else {
                setPaymentModalOpen(false);
              }
            }
          }}
        />
      )}

      <Modal
        wrapClassName="qu-modal"
        title={<h3>Incomplete Client Profile</h3>}
        // width={400}
        closeIcon={<IconsCommon.IconClose />}
        visible={clientRequiredFieldsOpen}
        onCancel={() => setClientRequiredFieldsOpen(false)}
        footer={false}
      >
        <div>
          <p className="mb-25">
            {
              "Profile is incomplete. Please update the required information in your profile, so that this campaign can be activated"
            }
          </p>
          <div className="grid-row grid-row--smg grid-row--jce">
            <div className="grid-col grid-col--auto">
              <QButton
                onClick={() => history.push(PATHS.CLIENT_PROFILE)}
                type="primary"
              >
                {"Edit Profile"}
              </QButton>
            </div>
            <div className="grid-col grid-col--auto">
              <QButton
                className="qu-button-outline"
                onClick={() => setClientRequiredFieldsOpen(false)}
              >
                {"Cancel"}
              </QButton>
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default React.memo(V3PaymentMethods);
