import React, { useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { useReduxDispatch } from '../../../../helpers'
import * as actions from '../activateAccount.actions'
import { Col, Form, QButton, QInput, Row } from 'quantum_components'
import { Button } from 'antd'
import { Link } from 'react-router-dom'
import AuthLayout from '../../../../components/Layouts/AuthLayout'
import { useTranslation } from 'react-i18next'
import { validateMessages } from '../../../../helpers/validationMessage'
import { PATHS } from 'src/constants'
import SpinnerCenter from "src/components/Spinner/spinner";
import { useSelector } from "react-redux";
// import { getIsLoading } from "../activateAccount.selectors";

const ActivateAccount = () => {
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  // const isLoading = useSelector(getIsLoading)
  // console.log(isLoading)
  const user = {
    id: urlParams.get('id'),
    email: urlParams.get('email')
  }

  // const { token } : any = useParams()
  const { t } = useTranslation(['translationUser', 'translationCommon'])
  
  // const queryString = window.location.search;
  // const urlParams = new URLSearchParams(queryString);
  const briefId = urlParams.has('resumeBriefId') ? urlParams.get('resumeBriefId') : "";

  const dispatch = useReduxDispatch()

  // const activateAccount = () => {
  //   // console.log(token,briefId)
  //   dispatch(actions.activateAccount(token,briefId))
  // }


  const submit = ({ confirmation_code }: any) => {
    dispatch(actions.activateAccountCognito(confirmation_code, user.id, user.email))
  }

  const handleResendCode = () => {
    console.log('handleResendCode')
    dispatch(actions.resendConfirmationCode(user.email))
  }

  return (
    <AuthLayout AuthPageLadyImageMaxWidth={"400px"}>
      <div className="auth-form">
        <h1>{t("user.reset-password.enter_confirmation_code")}</h1>
        <p>{t("user.reset-password.enter_confirmation_code_sent", {email: user.email})}.</p>
        <Button type="link" size="small" className="mb-20" style={{fontWeight:400, fontSize: 13, paddingInlineStart: 0}} onClick={()=>handleResendCode()}>
          Resend code.
        </Button>
        <Form layout="vertical" hideRequiredMark validateMessages={{ ...validateMessages, required: t('required field') }} onFinish={submit}>
          <Row gutter={16}>
            <Col span={24}>
              <Form.Item
                name="confirmation_code"
                rules={[
                  {
                    required: true,
                    whitespace: true,
                    max: 255,
                  },
                ]}
                validateTrigger={['onChange', 'onBlur']}
              >
                <QInput placeholder="000000" type="text" size="large" />
              </Form.Item>
            </Col>
          </Row>
          <QButton className="text-capitalize mt-16" type="primary" block htmlType="submit">
            {t("user.reset-password.next")}
          </QButton>
        </Form>
      </div>
      <div className="auth-section-footer">
        <p>
          {t("user.reset-password.back_to")} <Link to={PATHS.SIGN_UP}>{t("user.reset-password.sign_up")}</Link>
        </p>
      </div>
    </AuthLayout>
  )
}

export default ActivateAccount
