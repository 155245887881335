import React, { useEffect, useState } from 'react'
import { QFileUploader, qnotification } from 'quantum_components'
import { ALERT_BRIEF_UPLOAD_MAX } from 'src/constants/alerts'
import NOTIFICATION_TYPES from 'src/constants/notificationTypes'
import IUploadFile from 'src/interfaces/IUploadFile'
import ILocalFile from 'src/interfaces/ILocalFile'
import { validateApplication, validateImage, validateVideo } from 'src/helpers/validation'
import { useTranslation } from 'react-i18next'

const MAX_UPLOAD_COUNT = 5

interface Props {
  className?: string
  fileName?: string
  files: IUploadFile[] | any
  onChange?: (file: File[], param?: any) => void
  onRemove?: (file: ILocalFile | IUploadFile) => void
  onView?: (file: ILocalFile | IUploadFile) => void
  maxCount?: number
  viewOnly?: boolean
  isUploading?: boolean
  isRemoving?: boolean
  multiple?: boolean
  disabled?: boolean
  alternativeFileView?: boolean
  showRemoveButton?: boolean
  showPreview?: boolean
  disableView?: boolean
  disableRemove?: boolean
  dragAndDrop?: boolean
  caption?: string[]
  heading?: string
  uploadStepHeading?: string
  accept?: string
  listType?: string
  isVideo?: boolean
  defaultFileList?: any[]
  isTestahel?: boolean
  viewOnlyText?: string
  viewText?: string
  removeText?: string
}

const UploadComponent = ({
  className = '',
  fileName = '',
  files,
  onChange = () => { },
  onRemove = () => { },
  onView = () => { },
  maxCount = 1,
  viewOnly = false,
  isUploading = false,
  isRemoving = false,
  multiple = false,
  disabled = false,
  alternativeFileView = false,
  showRemoveButton = true,
  showPreview = true,
  disableView = false,
  disableRemove = false,
  dragAndDrop = true,
  caption = [],
  heading = 'Upload Image',
  accept = '.jpg, .jpeg, .png, .gif',
  uploadStepHeading = '',
  listType = 'picture',
  isVideo = false,
  defaultFileList = [],
  isTestahel = false,
  viewText,
  viewOnlyText,
  // viewOnlyText = 'View',
  // viewText = 'View',
  removeText = 'Remove',
}: Props) => {
  const { t } = useTranslation(['translation'])
  const [uploads, setUploads] = useState<File[]>([])
  let filesCache = [] as File[]
  caption = caption ?? [t('brief.upload.max_dimensions'), t('brief.upload.max_file_size')]
  // heading = heading ? heading : t('brief.upload.heading')
  heading = "Upload Media"

  viewOnlyText = viewOnlyText ?? "View";
  viewText = viewText ?? "View";
  // heading = uploadStepHeading ? uploadStepHeading : (heading ? t('brief.upload.heading') : t('brief.upload.heading'))

  useEffect(() => {
    if (!uploads.length) {
      return
    }

    const validatedFiles = uploads.filter((file: File) => {
      if (isVideo) {
        return validateVideo(file, fileName)
      }

      const generalFileType = file.type.split('/')[0]

      switch (generalFileType) {
        case 'video':
          return validateVideo(file, fileName)
        case 'image':
          return validateImage(file)
        case 'application':
          return validateApplication(file)
        default:
          return true
      }
    })

    if (!validatedFiles.length) {
      return
    }

    const sliced = validatedFiles.slice(0, maxCount - files.length)

    if (isTestahel) {
      if (!sliced.length) {
        qnotification({ type: NOTIFICATION_TYPES.ERROR, message: ALERT_BRIEF_UPLOAD_MAX(maxCount) })
      } else if (uploads.length !== sliced.length) {
        qnotification({ type: NOTIFICATION_TYPES.ERROR, message: ALERT_BRIEF_UPLOAD_MAX(maxCount) })
      }
      onChange(validatedFiles)
    } else {
      if (!sliced.length) {
        qnotification({ type: NOTIFICATION_TYPES.ERROR, message: ALERT_BRIEF_UPLOAD_MAX(maxCount) })
        return
      }
      onChange(sliced)
    }
  }, [uploads])

  const handleChange = (info: any) => {
    if (info.file.status === 'removed') {
      return
    }

    filesCache = [...filesCache, info.file]
    setUploads(filesCache)
  }

  const handleRemove = (file: IUploadFile) => {
    onRemove(file)
  }

  const getProps = () => {
    const multipleUploadProps = {
      fileName,
      accept,
      listType,
      fileList: files,
      dragAndDrop,
      viewOnly,
      maxCount,
      showUploadList: dragAndDrop
        ? {
          removeIcon: <span>Remove</span>,
        }
        : false,
      multiple,
      onChange: handleChange,
      onRemove: handleRemove,
      beforeUpload: () => false,
      onView,
      isUploading,
      isRemoving,
      disabled,
      alternativeFileView,
      showRemoveButton,
      showPreview,
      disableView,
      disableRemove,
      caption,
      heading,
      defaultFileList,
      viewOnlyText,
      viewText,
      removeText
    }

    const singleUploadProps = {
      maxCount,
      fileName,
      accept,
      dragAndDrop,
      viewOnly,
      fileList: files,
      image: files[0],
      showUploadList: false,
      multiple,
      onChange: handleChange,
      onRemove: handleRemove,
      beforeUpload: () => false,
      onView,
      isUploading,
      isRemoving,
      disabled,
      alternativeFileView,
      showRemoveButton,
      showPreview,
      disableView,
      disableRemove,
      caption,
      heading,
      viewOnlyText,
      viewText,
      removeText
    }
    return multiple ? multipleUploadProps : singleUploadProps
  }

  const uploadProps = getProps()

  // @ts-ignore
  return <QFileUploader className={className} {...uploadProps} />
}

export default UploadComponent
