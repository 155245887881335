export default {
  ROOT: '/',
  NOT_FOUND: '404',
  SERVER_ERROR: '500',
  HOME_PAGE: '/home',
  SIGN_IN: '/login',
  SIGN_UP: '/signup/client',
  UPDATE_PASSWORD: '/update-password',
  UPDATE_EMAIL: '/update-email',
  RESET_PASSWORD: '/reset-password',
  // RESTORE_PASSWORD: '/restore-password',
  RESTORE_PASSWORD: '/restore-password',
  ACTIVATE_ACCOUNT: '/activate-account',
  SPECIAL_CLIENTS: '/p/:id',
  REGISTER_SUCCESS: '/register-success',
  PARTIALLY_REGISTER: '/signup/client/:socialToken/:briefId/:socialType',
  DEPLOYMENT_DETAILS: '/deployment-details',

  //Client Routes
  CLIENT_PROFILE: '/client/client-profile',
  CLIENT_PROFILE_PREVIEW: '/client/client-profile-preview',
  BILLS: '/bills',
  ANNOUNCEMENTS: '/announcements',
  ANNOUNCEMENTS_BY_ID: '/announcements/:id',
  BRIEF: '/briefs',
  BRIEF_VIEW: '/briefs/view/:id',
  CREATE_BRIEF: '/briefs/create',
  BRIEF_EDIT: '/briefs/edit/:id',
  BRIEF_REJECTED: '/briefs/rejected/:id',

  // brief v2
  BRIEF_EDIT_V2: '/briefs/v2/edit/:id',

  // brief v3
  BRIEF_EDIT_V3: '/briefs/v3/edit/:id',

  TESTAHEL_BRIEF_VIEW: '/testahel-brief/view/:id',
  TESTAHEL_BRIEF_CREATE: '/testahel-brief/create',
  TESTAHEL_BRIEF_EDIT: '/testahel-brief/edit/:id',
  MARKETPLACE: '/marketplace',
  CAMPAIGNS: '/campaigns',
  CAMPAIGNS_V3: '/campaigns/v3/:id',
  CAMPAIGN_VIEW: '/campaigns/:id',

  //Partner routes
  PARTNER_DASHBOARD: '/partner/dashboard',
  PUBLIC_PROFILE: '/partner/public-profile',
  PARTNER_PROFILE: '/partner/partner-profile',
  REQUESTS: '/requests',
  REQUEST_DETAILS: '/requests/:id',
  PARTNER_CAMPAIGNS: '/partner/campaigns',
  TEAM_MEMBERS: '/partner/team-members',
  INVITE_CLIENT: '/partner/invite-client',
  BILLINGS: '/partner/billings',
  PARTNER_API: '/partner/partner-api',
}
