import React, { ReactElement } from 'react'
import { IconsCommon, Modal, QButton } from 'quantum_components'

import { ReactComponent as CircleCheckIcon } from '../../assets/icons/circle-check-icon.svg';

const closeIcon = <IconsCommon.IconClose />

interface IProps {
  modalOpen: boolean
  question: string
  description: string
  submitButtonText: string
  cancelButtonText: string
  onClose: () => void
  onProceed: () => void
  revert?: boolean
}

export default function DialogModal({
  modalOpen = false,
  question,
  description,
  submitButtonText,
  cancelButtonText,
  onClose,
  onProceed,
  revert = false,
}: IProps): ReactElement {
  return (
    <Modal
      wrapClassName="qu-modal"
      title={<h3>{question}</h3>}
      width={400}
      closeIcon={closeIcon}
      visible={modalOpen}
      onCancel={onClose}
      footer={false}
    >
      <div>
        {description.split('\n').map((str, index) => (
          <p key={index} className="mb-25">
            {str}
          </p>
        ))}
        <div className="align-vertical text-center">
          <QButton onClick={revert ? onProceed : onClose} className="w-280 mb-20" type="primary">
            <span className="mr-5">{revert ? cancelButtonText : submitButtonText}</span>
            <CircleCheckIcon />
          </QButton>
          <button onClick={revert ? onClose : onProceed} type="button" className="button button--link mb-10">
            {revert ? submitButtonText : cancelButtonText}
          </button>
        </div>
      </div>
    </Modal>
  )
}
