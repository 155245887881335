import { ThunkAction, ThunkDispatch } from 'redux-thunk'
import { AnyAction } from 'redux'
import { AxiosResponse } from 'axios'
import { qnotification } from 'quantum_components'
import { getUser } from '../../core/core.actions'
import IAction from 'src/interfaces/IAction'
import { requestHttp, urls } from 'src/api'
import { ISignInResponse } from './signIn.types'
import * as CONSTANTS from './signIn.constants'
import { LOCAL_STORAGE_KEYS, PATHS, ROLES, NOTIFICATION_TYPES, LOCALIZATION_LANGUAGES } from 'src/constants'
import { getResponseErrorMessage } from 'src/helpers'
import localStorage from 'redux-persist/es/storage'
import history from '../../../utils/history'
import { SocialFormValues } from 'src/modules/registration/signUp/containers/SignUp.container'
import { ISignUpResponse } from '../signUp/signUp.types'

export const resetState = (): IAction => ({ type: CONSTANTS.RESET_STATE })

export const signInRequest = (): IAction => ({
  type: CONSTANTS.SIGN_IN_REQUEST,
})

export const signInSuccess = (email: string, accessToken: string, role: ROLES): IAction => ({
  type: CONSTANTS.SIGN_IN_SUCCESS,
  payload: { email, accessToken, role },
})

export const quickSignInRequest = (): IAction => ({
  type: CONSTANTS.QUICK_SIGN_IN_SUCCESS,
})

export const signInSuccessLoading = (): IAction => ({
  type: CONSTANTS.SIGN_IN_SUCCESS_LOADING,
})

export const signInFailure = (error: string): IAction => ({
  type: CONSTANTS.SIGN_IN_FAILURE,
  error,
})

export const signInErrorReset = (): IAction => ({
  type: CONSTANTS.SIGN_IN_RESET_ERROR,
})

export const signIn = (
  email: string,
  password: string,
  token: any,
  specialId: any,
  briefId: any,
  resumeBriefId: any
): ThunkAction<Promise<AxiosResponse<ISignInResponse>>, {}, {}, AnyAction> => async (
  dispatch: ThunkDispatch<{}, {}, AnyAction>
): Promise<AxiosResponse<ISignInResponse>> => {
    try {
      dispatch(signInRequest())

      let payload
      if (token && specialId)
        payload = {
          email,
          password,
          inviteToken: token,
          partnerData: specialId,
        }
      else payload = { email, password }

      const response = await requestHttp.post<ISignInResponse>(urls.getLoginUrl(), payload)
      
      if (response.status === 200) {
        const { content } = response.data.data

        if (![ROLES.CLIENT].includes(content.role)) {
          qnotification({ type: NOTIFICATION_TYPES.ERROR, message: 'Authorization Forbidden' })
          dispatch(signInFailure('Authorization Forbidden'))
          return response
        }

        dispatch(signInSuccess(content.email, content.token, content.role))
        await localStorage.setItem(LOCAL_STORAGE_KEYS.USER_ROLE, content.role)
        await localStorage.setItem(LOCAL_STORAGE_KEYS.ACCESS_TOKEN, content.token)

        if (!(await localStorage.getItem(LOCAL_STORAGE_KEYS.LOCALIZATION))) {
          await localStorage.setItem(LOCAL_STORAGE_KEYS.LOCALIZATION, LOCALIZATION_LANGUAGES.ENGLISH)
        }

        dispatch(getUser())

        if (content.role === ROLES.PARTNER) {
          history.push(PATHS.PARTNER_DASHBOARD)
        } else {
          if (resumeBriefId) {
            // resume brief flow after invite client registers
            history.push(PATHS.BRIEF_EDIT.replace(':id', resumeBriefId))
          } else if (token && specialId && briefId) {
            // resume brief flow after invite client logs in
            history.push(PATHS.BRIEF_EDIT.replace(':id', briefId))
          } else {
            // not an invite client flow
            history.push(PATHS.BRIEF)
          }


        }
      
      } else if (response.status === 400 && response.data.error.message === "User is not confirmed.") {
        dispatch(signInFailure(response?.statusText))
        //verification code needed
        history.push(`${PATHS.ACTIVATE_ACCOUNT}?id=${response.data.error.payload.id}&email=${encodeURIComponent(email)}`)
      } else {
        dispatch(signInFailure(response?.statusText))
      }
      return response
    } catch (error) {
      dispatch(signInFailure(getResponseErrorMessage(error)))
      return error
    }
  }

export const socialSignIn = (
  socialToken: string,
  socialType: string,
  token: any,
  specialId: any,
  briefId: any,
  resumeBriefId: any
): ThunkAction<Promise<AxiosResponse<ISignInResponse>>, {}, {}, AnyAction> => async (
  dispatch: ThunkDispatch<{}, {}, AnyAction>
): Promise<AxiosResponse<ISignInResponse>> => {
    try {
      dispatch(signInRequest())

      let payload
      if (token && specialId)
        payload = {
          socialToken,
          socialType,
          inviteToken: token,
          partnerData: specialId,
        }
      else payload = { socialToken, socialType }

      const response = await requestHttp.post<ISignInResponse>(urls.getSocialLoginUrl(), payload)

      if (response.status === 200) {
        const { content } = response.data.data

        if (content.partiallyRegistered) {
          history.push(`/signup/client/${content.socialToken}/${briefId}/${content.socialType}`)
          dispatch(signInSuccessLoading())
          return response
        }

        if (![ROLES.CLIENT].includes(content.role)) {
          qnotification({ type: NOTIFICATION_TYPES.ERROR, message: 'Authorization Forbidden' })
          dispatch(signInFailure('Authorization Forbidden'))
          return response
        }

        dispatch(signInSuccess(content.email, content.token, content.role))
        await localStorage.setItem(LOCAL_STORAGE_KEYS.USER_ROLE, content.role)
        await localStorage.setItem(LOCAL_STORAGE_KEYS.ACCESS_TOKEN, content.token)

        if (!(await localStorage.getItem(LOCAL_STORAGE_KEYS.LOCALIZATION))) {
          await localStorage.setItem(LOCAL_STORAGE_KEYS.LOCALIZATION, LOCALIZATION_LANGUAGES.ENGLISH)
        }

        dispatch(getUser())

        if (content.role === ROLES.PARTNER) {
          history.push(PATHS.PARTNER_DASHBOARD)
        } else {
          if (resumeBriefId) {
            // resume brief flow after invite client registers
            history.push(PATHS.BRIEF_EDIT.replace(':id', resumeBriefId))
          } else if (token && specialId && briefId) {
            // resume brief flow after invite client logs in
            history.push(PATHS.BRIEF_EDIT.replace(':id', briefId))
          } else if (briefId && Number(briefId) > 0) {
            // resume brief flow after fully registered with the social
            history.push(PATHS.BRIEF_EDIT.replace(':id', briefId))
          } else {
            // not an invite client flow
            history.push(PATHS.BRIEF)
          }
        }
      } else {
        dispatch(signInFailure(response?.statusText))
      }
      return response
    } catch (error) {
      dispatch(signInFailure(getResponseErrorMessage(error)))
      return error
    }
  }

export const socialSignUp = (
  data: SocialFormValues,
  token: any,
  specialId: any,
  briefId: any,
  resumeBriefId: any
): ThunkAction<Promise<AxiosResponse<ISignUpResponse>>, {}, {}, AnyAction> => async (
  dispatch: ThunkDispatch<{}, {}, AnyAction>
): Promise<AxiosResponse<ISignUpResponse>> => {
    try {
      dispatch(signInRequest())

      let payload
      if (token && specialId)
        payload = {
          ...data,
          inviteToken: token,
          partnerData: specialId,
          briefId
        }
      else payload = { ...data }

      const response = await requestHttp.post(urls.getSocialSignUpUrl(), payload)

      if (response.status === 201) {

        await localStorage.removeItem(LOCAL_STORAGE_KEYS.USER_ROLE);
        await localStorage.removeItem(LOCAL_STORAGE_KEYS.ACCESS_TOKEN);
        await localStorage.removeItem(LOCAL_STORAGE_KEYS.INVITER_SPECIAL_ID);
        await localStorage.removeItem(LOCAL_STORAGE_KEYS.INVITER_SPECIAL_TOKEN);
        await localStorage.removeItem(LOCAL_STORAGE_KEYS.INVITER_PARTNER_LOGO);

        await sessionStorage.removeItem(LOCAL_STORAGE_KEYS.INVITER_SPECIAL_ID)
        dispatch(signInSuccessLoading())

        if (response?.data?.data?.content?.partiallyRegistered) {
          history.push(`/signup/client/${response?.data?.data?.content?.socialToken}/${briefId}/${response?.data?.data?.content?.socialType}`)
        } else if (response?.data?.data?.content?.fullyRegistered) {
          if (!data.password && !data.email) history.push('/login')
          else await dispatch(
            socialSignIn(
              data.socialToken,
              data.socialType,
              token,
              specialId,
              briefId,
              resumeBriefId
            )
          );
        }
      } else {
        if (!data.password && !data.email) history.push('/login')
        dispatch(signInFailure(getResponseErrorMessage(null)))
      }

      return response
    } catch (error) {
      dispatch(signInFailure(getResponseErrorMessage(error)))

      return error
    }
  }

export const quickSignIn = (email: string): ThunkAction<Promise<AxiosResponse<ISignInResponse>>, {}, {}, AnyAction> => async (
  dispatch: ThunkDispatch<{}, {}, AnyAction>
): Promise<AxiosResponse<ISignInResponse>> => {
  try {
    dispatch(signInRequest())

    const response = await requestHttp.post<ISignInResponse>(urls.getQuickLoginUrl(), { email })

    if (response.status === 200) {
      dispatch(quickSignInRequest())
      return response
    } else {
      dispatch(signInFailure(response?.statusText))
    }
    return response
  } catch (error) {
    dispatch(signInFailure(getResponseErrorMessage(error)))
    return error
  }
}